import React from 'react';
import PropTypes from 'prop-types';

import { loginWithToken } from '../../lib/api';
import {getSessionId, setSessionId, removeSessionId, removeLoginToken} from '../../lib/storage';

const DEFAULT_REDIRECT = 'profile';

class LoginAndRedirect extends React.Component {
  componentDidMount() {
    const { stay, secret, target } = this.props;
    const sessionId = getSessionId();

    const redirectMe = () => {
      window.location = `/${target || DEFAULT_REDIRECT}`;
    };
    const redirectHome = () => {
      if (stay) {
        window.location = `/${target || DEFAULT_REDIRECT}`;
      } else {
        window.location = `/login`;
      }
    };

    if (secret === sessionId) {
      return redirectMe();
    } else {
      loginWithToken(secret)
        .then(({ _id: sessionId }) => {
          setSessionId(decodeURIComponent(sessionId));
          return redirectMe();
        })
        .catch((ex) => {
          if (ex.statusCode === 401) {
            removeSessionId();
            removeLoginToken();
          } else {
            console.error(ex);
          }
          redirectHome();
        });
    }
  }

  render() {
    return (
      <div className="redirect">
        <h1>Loggar in...</h1>
      </div>
    );
  }
}

LoginAndRedirect.propTypes = {
  stay: PropTypes.bool,
  secret: PropTypes.string,
  target: PropTypes.string,
};

LoginAndRedirect.defaultProps = {
  stay: false,
  secret: '',
  target: '',
};

export default LoginAndRedirect;
